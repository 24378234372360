exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-financial-support-disabled-facilities-grant-tsx": () => import("./../../../src/pages/financial-support/disabled-facilities-grant.tsx" /* webpackChunkName: "component---src-pages-financial-support-disabled-facilities-grant-tsx" */),
  "component---src-pages-financial-support-tsx": () => import("./../../../src/pages/financial-support.tsx" /* webpackChunkName: "component---src-pages-financial-support-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-returns-tsx": () => import("./../../../src/pages/legal/returns.tsx" /* webpackChunkName: "component---src-pages-legal-returns-tsx" */),
  "component---src-pages-register-warranty-tsx": () => import("./../../../src/pages/register-warranty.tsx" /* webpackChunkName: "component---src-pages-register-warranty-tsx" */),
  "component---src-pages-save-discount-tsx": () => import("./../../../src/pages/save-discount.tsx" /* webpackChunkName: "component---src-pages-save-discount-tsx" */),
  "component---src-pages-vat-exemption-apply-tsx": () => import("./../../../src/pages/vat-exemption/apply.tsx" /* webpackChunkName: "component---src-pages-vat-exemption-apply-tsx" */),
  "component---src-pages-vat-exemption-buy-tsx": () => import("./../../../src/pages/vat-exemption/buy.tsx" /* webpackChunkName: "component---src-pages-vat-exemption-buy-tsx" */),
  "component---src-pages-vat-exemption-success-tsx": () => import("./../../../src/pages/vat-exemption/success.tsx" /* webpackChunkName: "component---src-pages-vat-exemption-success-tsx" */),
  "component---src-templates-contentful-page-template-tsx": () => import("./../../../src/templates/contentfulPage.template.tsx" /* webpackChunkName: "component---src-templates-contentful-page-template-tsx" */),
  "component---src-templates-personal-case-study-template-tsx": () => import("./../../../src/templates/PersonalCaseStudy.template.tsx" /* webpackChunkName: "component---src-templates-personal-case-study-template-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

