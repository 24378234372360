import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import Client from "shopify-buy";
// declare gtag
declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

const currencies = {
  nl: "EUR",
  en: "GBP",
  "en-GB": "GBP",
  "en-US": "USD",
  de: "EUR",
  fr: "EUR",
  it: "EUR",
  es: "EUR",
};
export interface DiscountApplication {
  allocationMethod: string;
  targetSelection: string;
  targetType: string;
  code: string;
  value: {
    percentage: string;
    amount: string;
  };
}

export interface Checkout {
  lineItems: any[];
  webUrl: string;
  id: string;
  discountApplications: DiscountApplication[];
  totalPriceV2: {
    currencyCode: string;
    amount: string;
  };
}

interface IDefault {
  checkout: Checkout;
  cart: any;
  isOpen: boolean;
  loading: boolean;
  currency: string | null;
  currencySymbol: string;
  onOpen: () => void;
  onClose: () => void;
  addVariantToCart: (variantId: string, quantity: number) => void;
  removeLineItem: (checkoutId: any, itemId: any) => void;
  updateLineItem: (checkoutID: any, itemId: any, value: any) => void;
  addDiscount: (discountCode: string) => Promise<any>;
  removeDiscount: () => Promise<any>;
  setLoading: (loading: boolean) => void;
  client: any;
  showCart: boolean;
  setShowCart: (show: boolean) => void;
  didJustAddToCart: boolean;
  setCurrentLanguage: (language: string) => void;
  setCurrency: (currency: string) => void;
  setDiscountCode: (discountCode: string) => Promise<any>;
  discountCode: string;
  currentLanguage: string | null;
}

const defaultValues: IDefault = {
  cart: null,
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  setLoading: (loading: boolean) => {},
  addVariantToCart: (variantId: string, quantity: number) => {},
  removeLineItem: (checkoutId: any, itemId: any) => {},
  updateLineItem: (checkoutID: any, itemId: any, value: any) => {},
  addDiscount: (discountCode: string) => new Promise((resolve, reject) => {}),
  removeDiscount: () => new Promise((resolve, reject) => {}),
  setDiscountCode: (discountCode: string) => {},
  discountCode: "",
  didJustAddToCart: false,
  currency: "GBP",
  currencySymbol: "£",
  client: null,
  showCart: false,
  setCurrentLanguage: (language: string) => {},
  setShowCart: () => {},
  setCurrency: (currency: string) => {},
  checkout: {
    lineItems: [],
    webUrl: "",
    id: "",
    discountApplications: [],
    totalPriceV2: {
      currencyCode: "",
      amount: "",
    },
  },
  currentLanguage: "",
};

export const CURRENCY_SYMBOLS = {
  GBP: "£",
  USD: "$",
  EUR: "€",
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

// Setup the Shopify client on mount
const client = Client.buildClient({
  domain: "shop.idry.me",
  storefrontAccessToken: "d8f6b6c09548d1a0159d74182039c996",
  apiVersion: "2023-10",
});

export const StoreProvider = ({ children }: any) => {
  const { language } = useI18next();

  const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
  const [checkout, setCheckout] = useState<any>(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [currency, setCurrency] = useState<string | null>(null);
  const [currencySymbol, setCurrencySymbol] = useState("£");
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);
  const [discountCode, setDiscountCodeI] = useState("");

  const setDiscountCode = async (discountCode: string) => {
    setDiscountCodeI(discountCode);
    return Promise.resolve(true);
  }

  useEffect(() => {
    console.log(checkout)
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckout(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.removeItem(localStorageKey);
        }
      }

      if (client) {
        const newCheckout = await client.checkout.create({
          presentmentCurrencyCode: currencies[currentLanguage as keyof typeof currencies],
        });
        setCheckout(newCheckout);
        if (isBrowser) {
          localStorage.setItem(localStorageKey, newCheckout.id);
        }
      }
    };

    if (client) {
      initializeCheckout();
    }
  }, [client, currentLanguage]);

  const addVariantToCart = (variantId: string, quantity: number) => {
    setLoading(true);

    const checkoutID = (checkout as any).id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: quantity,
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res: any) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
      });
  };

  const removeDiscount = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      setLoading(true);

      try {
        const checkoutID = (checkout as any).id;
        if (!checkoutID) {
          reject("Checkout not found");
        }

        client.checkout
          .removeDiscount(checkoutID)
          .then((res: any) => {
            setCheckout(res);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error removing discount:", error);
            setLoading(false);
            reject(error);
          });

        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  };

  const addDiscount = async (discountCode: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      setLoading(true);

      try {
        const checkoutID = (checkout as any).id;
        if (!checkoutID) {
          reject("Checkout not found");
        }

        if (checkout.discountApplications.length > 0) {
          await removeDiscount().catch((error) => {
            console.error("Error removing discount:", error);
            setLoading(false);
            reject(error);
          });
        }

        client.checkout
          .addDiscount(checkoutID, discountCode)
          .then((res: any) => {
            setCheckout(res);
            setLoading(false);
            if (window.gtag) {
              window.gtag("event", "discount_code_applied", {
                event_category: "discount_code",
                event_label: discountCode,
              });
            }
    
          })
          .catch((error) => {
            console.error("Error adding discount:", error);
            setLoading(false);
            reject(error);
          });
        resolve(true);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const removeLineItem = (checkoutID: any, lineItemID: any) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res: any) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (checkoutID: any, lineItemID: any, quantity: any) => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res: any) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  useEffect(() => {
    setShowCart(checkout.lineItems.length > 0);
  }, [checkout.lineItems.length]);

  useEffect(() => {
    const currencyCode = checkout.currencyCode;

    const createNewCheckout = async () => {
      if (currencyCode) {
        if (currency && currency !== currencyCode) {
          const lineItems = checkout.lineItems;
          const newCheckout = await client.checkout.create({
            presentmentCurrencyCode: currency,
          });
          setCheckout(newCheckout);
          if (isBrowser) {
            localStorage.setItem(localStorageKey, newCheckout.id);
          }
        }
      }
    };

    if (currentLanguage) {
      createNewCheckout();
    }
  }, [currentLanguage, currency]);

  useEffect(() => {
    if (currencies[currentLanguage as keyof typeof currencies] === currency) {
      return;
    }

    const newCurrency = currencies[currentLanguage as keyof typeof currencies];
    setCurrency(newCurrency);
    setCurrencySymbol(CURRENCY_SYMBOLS[newCurrency as keyof typeof CURRENCY_SYMBOLS]);
  }, [currentLanguage]);

  useEffect(() => {
    if (discountCode && discountCode.length > 0 && checkout.id) {
      addDiscount(discountCode);
    }
  }, [discountCode, checkout.id]);

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        checkout,
        showCart,
        currency,
        currencySymbol,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        addDiscount,
        removeDiscount,
        setLoading,
        setShowCart,
        setCurrency,
        setCurrentLanguage,
        currentLanguage,
        discountCode,
        setDiscountCode,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
