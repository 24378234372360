module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HNTYH2LLK2","AW-10849254629"],"gtagConfig":{"allowLinker":true,"cookie_expires":0,"anonymize_ip":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"24965116","respectDNT":false,"productionOnly":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"735935278649817"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-GB","nl","en-US"],"defaultLanguage":"en-GB","siteUrl":"https://www.idry.me","i18nextOptions":{"fallbackLng":"en-GB","supportedLngs":["en-GB","nl","en-US"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
