import React from "react";
import { StoreProvider } from "./src/context/store";
import { IntProvider } from "./src/context/int";
import i18n from "./src/components/i18n";
import { I18nextProvider } from "react-i18next";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}>
    <IntProvider>
      <StoreProvider>{element}</StoreProvider>
    </IntProvider>
  </I18nextProvider>
);
