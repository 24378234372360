import { useI18next } from "gatsby-plugin-react-i18next";
import React, { createContext, useContext, useEffect } from "react";
import { navigate } from "gatsby";

export const IntContext = createContext<any>(null);

export const useTimer = () => useContext(IntContext);

const USA_DOMAINS = ["localhost", "idrybodydryer.com"];

export const IntProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { language, changeLanguage } = useI18next();

  return <IntContext.Provider value={{}}>{children}</IntContext.Provider>;
};
